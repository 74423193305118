import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import { FC } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "grid",
      gridTemplateAreas: "spot",
      position: "relative",
    },
    foreground: { gridArea: "spot", strokeLinecap: "round" },
    background: { gridArea: "spot", opacity: 0.1 },
    children: {
      position: "absolute",
      top: "52%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  }),
  {
    classNamePrefix: "CircularProgressWithBackground",
  }
);

export type CircularProgressWithBackgroundJSSClassKey = keyof ReturnType<typeof useStyles>;

export type CircularProgressWithBackgroundProps = {
  classes?: Partial<ClassNameMap<CircularProgressWithBackgroundJSSClassKey>>;
  className?: string;
  size?: number;
  thickness?: number;
  fraction: number;
  color?: CircularProgressProps["color"];
};

export const CircularProgressWithBackground: FC<CircularProgressWithBackgroundProps> = ({
  className,
  classes: extClasses,
  fraction,
  size = 75,
  thickness = size / 15,
  children,
  color = "primary"
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Box className={clsx(classes.root, className)}>
      <CircularProgress
        className={classes.foreground}
        thickness={thickness}
        size={size}
        variant="determinate"
        value={fraction * 100}
        color={color}
      />
      <CircularProgress
        className={classes.background}
        thickness={thickness}
        size={size}
        variant="determinate"
        value={100}
      />
      {children && <Box className={classes.children}>{children}</Box>}
    </Box>
  );
};
