import { add } from "date-fns";
import { nanoid } from "nanoid";
import { concat, debounce, forEach, fromValue, interval, makeSubject, map, onEnd, onStart, pipe } from "wonka";
import { Client } from ".";
import { HttpErrorStatusCode } from "../types/https";
import { transformMap } from "../utils/arrays";
import {
  isClientNetworkError,
  isReclaimApiError,
  normalizeClientNetworkError,
  normalizeReclaimApiError,
} from "../utils/client.utils";
import { deepmerge, partialEquals, walk } from "../utils/objects";
import { isEnvelope } from "../utils/wonka";
import { Envelope, ReclaimWS } from "./adaptors/ws";
import { NetworkError, ReclaimApi, ReclaimApiError, SubscriptionType, TypedErrorPromise } from "./client";

// Source in API: ReclaimErrorCode.java
export type ReclaimErrorCode =
  | "ENTITLEMENT_MAX_CONNECTED_CALENDARS"
  | "ENTITLEMENT_MAX_CALENDAR_SYNCS"
  | "ENTITLEMENT_MAX_HABITS"
  | "ENTITLEMENT_MAX_CUSTOM_TIME_SCHEMES"
  | "ENTITLEMENT_NO_SMART11S"
  | "ENTITLEMENT_NO_ASANA"
  | "ENTITLEMENT_NO_CLICKUP"
  | "ENTITLEMENT_NO_JIRA"
  | "ENTITLEMENT_NO_LINEAR"
  | "ENTITLEMENT_NO_TODOIST"
  | "UNKNOWN"
  | "TEAM_INVITE_EXISTS";

export type ReclaimErrorBody = {
  errorCode?: ReclaimErrorCode;
  reclaimError?: true;
};

export function nullable<T>(val: T | null | undefined): string | null | undefined;
export function nullable<T, R = string>(val: T | null | undefined, fn?: (v?: T) => R | undefined): R | null | undefined;
export function nullable<T, R = string>(
  val: T | null | undefined,
  fn?: (v?: T) => R | undefined
): R | null | undefined {
  if (val === null) return null;
  return !!fn ? fn(val) : (`${val}` as unknown as R);
}

export type ParsedNotification = {
  resource?: string;
  pk?: string;
  action?: string;
  uuid?: string;
};

export const parseNotificationUuid = (key: string): ParsedNotification => {
  const parts = key.split("_");

  const uuid = parts.pop();
  const action = parts.pop();
  const resource = parts.shift();
  const pk = parts.shift();

  return {
    resource,
    pk,
    action,
    uuid,
  };
};

// export const nullable = <T, R = string>(
//   val: T | null | undefined,
//   fn = (v: T | undefined): R | undefined => (!!v ? `${v}` as unknown as R : undefined)
// ) => (val === null ? null : fn(val));

export type UnTypedPromise<T extends TypedErrorPromise<unknown, unknown>> = T extends TypedErrorPromise<infer U, Error>
  ? U
  : never;

export enum NotificationSyncStatus {
  SyncingServer = "SYNCING_SERVER",
  SyncingCalendar = "SYNCING_CALENDAR",
  Ready = "READY",
}

export enum NotificationKeyStatus {
  Pending = "PENDING",
  Requested = "REQUESTED",
  Received = "RECEIVED",
  Planned = "PLANNED",
  Timedout = "TIMEDOUT",
  Failed = "FAILED",
  Completed = "COMPLETED",
}

const NotificationStatusIndex = {
  // Syncing Reclaim
  [NotificationKeyStatus.Pending]: 0,
  [NotificationKeyStatus.Requested]: 1,
  [NotificationKeyStatus.Received]: 2,
  // Syncing Calendar
  [NotificationKeyStatus.Planned]: 3,
  // Clear
  [NotificationKeyStatus.Timedout]: 4,
  [NotificationKeyStatus.Failed]: 5,
  [NotificationKeyStatus.Completed]: 5,
};

export const DefaultErrorManagment = {
  [HttpErrorStatusCode.UNAUTHORIZED]: true,
  [HttpErrorStatusCode.NOT_FOUND]: true,
  [HttpErrorStatusCode.SERVICE_UNAVAILABLE]: false,
  [HttpErrorStatusCode.BAD_GATEWAY]: false,
};

export type ExpectedChange<RT = object> = {
  notificationKey: string | null;
  pk: string | number;
  expires: Date;
  patch: Partial<RT>;
  assist?: boolean;
  optimistic?: boolean;
  resource?: RT;
};

export type ExpectedChangesMap = Map<string, Set<ExpectedChange>>;
export type ReceivedChangesMap = Map<string, Set<object>>;

export type NotificationKeyRecord<RT = object> = {
  status: NotificationKeyStatus;
  resource?: RT;
  expectedChanges: ExpectedChangesMap;
  receivedChanges?: ReceivedChangesMap;
  assist: boolean;
  expires: Date;
};

export type ManageNotificationKeyErrorizer<F extends (...args: unknown[]) => Promise<unknown>> =
  | string
  | ((cause: unknown, ...args: Parameters<F>) => Error | string);

export type ManageNotificationKeyOptions<F extends (...args: unknown[]) => Promise<unknown>, RT> = {
  getPk?: (...args: Parameters<F>) => string | number | undefined;
  getPatch?: (...args: Parameters<F>) => Partial<RT>;
  optimistic?: boolean;
  errorizer?: ManageNotificationKeyErrorizer<F>;
};

export class Domain {
  constructor(
    protected readonly key: string,
    protected readonly api: ReclaimApi,
    protected readonly client: Client,
    protected readonly ws: ReclaimWS
  ) {}
  /**
   * manage 'yo errors
   * @deprecated use `typedManageErrors`
   */
  protected manageErrors<F extends (...args: any[]) => TypedErrorPromise<unknown, NetworkError | ReclaimApiError>>(
    method: F,
    handledErrors: {
      [status in HttpErrorStatusCode]?: boolean | ((error: ReclaimApiError) => boolean | void);
    } = DefaultErrorManagment
  ): (...args: Parameters<F>) => Promise<any> {
    return async (...args: unknown[]) => {
      return await method(...args).catch((e) => {
        if (isReclaimApiError(e)) {
          e = normalizeReclaimApiError(e as ReclaimApiError);
        } else if (isClientNetworkError(e)) {
          e = normalizeClientNetworkError(e as NetworkError);
        }

        if (e.status === "NetworkError") {
          throw e;
        }

        if (!!handledErrors[e.status]) {
          e.handled =
            typeof handledErrors[e.status] === "boolean"
              ? !!handledErrors[e.status]
              : handledErrors[e.status](e) || false;
        }

        console.debug("Response error", e.handled, e.status, e.message, e);
        if (!e.handled) throw e;
      });
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected typedManageErrors<F extends (...args: any[]) => TypedErrorPromise<unknown, NetworkError | ReclaimApiError>>(
    method: F,
    handledErrors: {
      [status in HttpErrorStatusCode]?: boolean | ((error: ReclaimApiError) => boolean | void);
    } = DefaultErrorManagment
  ): F {
    return this.manageErrors(method, handledErrors) as F;
  }
}

export abstract class ResourceDomain<RT = object, DT = object> extends Domain {
  abstract resource: string;
  abstract cacheKey: string;
  pk: string | string[] = "id";

  resourceType: RT = {} as RT;
  dtoType: DT = {} as DT;

  getPk(item: RT) {
    if (!item) return;

    return Array.isArray(this.pk)
      ? this.pk
          .map((k) => {
            const value = walk(k, item);
            // FIXME (IW): Why was the type added here?
            // If it's needed, use other than ":" as the delimiter
            return `(${value}:${typeof value})`;
          })
          .join("-")
      : item[this.pk];
  }
}

export function getSyncStatus(statuses: NotificationKeyStatus[]) {
  if (
    statuses.some((status) =>
      [NotificationKeyStatus.Pending, NotificationKeyStatus.Requested, NotificationKeyStatus.Received].includes(status)
    )
  ) {
    return NotificationSyncStatus.SyncingServer;
  } else if (statuses.some((status) => NotificationKeyStatus.Planned === status)) {
    return NotificationSyncStatus.SyncingCalendar;
  } else {
    return NotificationSyncStatus.Ready;
  }
}

const AssistPlannedSubscription = {
  subscriptionType: SubscriptionType.AssistCompleted,
};

const AssistCompletedSubscription = {
  subscriptionType: SubscriptionType.AssistCompleted,
};

export abstract class TransformDomain<RT, DT> extends ResourceDomain<RT, DT> {
  private static watching: boolean;

  private static notificationKeysSubject = makeSubject<Map<string, NotificationKeyRecord>>();
  private static notificationKeys: Map<string, NotificationKeyRecord> = new Map();

  // TODO (IW): There's some half-baked code included w/ notification keys in
  // attempt to merge expected changes into notification keys. The structures
  // are all there, but work is still needed to migrate from "expectChange" to
  // adding a notification key w/ the changes listed.

  protected static addNotificationKey = (
    key: string,
    expectedChanges: ExpectedChangesMap = new Map(),
    status: NotificationKeyStatus = NotificationKeyStatus.Pending,
    assist?: boolean,
    expires?: Date,
    receivedChanges: ReceivedChangesMap = new Map()
  ) => {
    const record: NotificationKeyRecord = {
      status,
      expectedChanges,
      receivedChanges,
      assist: !!assist,
      expires: !!expires ? expires : add(new Date(), { minutes: 1 }),
    };

    TransformDomain.notificationKeys.set(key, record);
    TransformDomain.notificationKeysSubject.next(TransformDomain.notificationKeys);

    return record;
  };

  protected static updateNotificationKey(key: string, envelope: Envelope): NotificationKeyRecord;
  protected static updateNotificationKey(key: string, status: NotificationKeyStatus): NotificationKeyRecord;
  protected static updateNotificationKey(
    key: string,
    statusOrEnvelope: NotificationKeyStatus | Envelope
  ): NotificationKeyRecord;
  protected static updateNotificationKey(key: string, statusOrEnvelope: NotificationKeyStatus | Envelope) {
    // No existing record
    if (!TransformDomain.notificationKeys.has(key)) {
      console.warn("Notification key doesn't exist, adding", key, statusOrEnvelope);
      return isEnvelope(statusOrEnvelope)
        ? this.addNotificationKey(
            key,
            undefined,
            NotificationKeyStatus.Received,
            undefined,
            undefined,
            statusOrEnvelope.data as ReceivedChangesMap // FIXME (IW): Parse data into map of resources (ReceivedChangesMap)
          )
        : this.addNotificationKey(key, undefined, statusOrEnvelope);
    }

    const record = TransformDomain.notificationKeys.get(key)!;

    // Update record
    if (!isEnvelope(statusOrEnvelope)) {
      if (!!record && NotificationStatusIndex[record.status] > NotificationStatusIndex[statusOrEnvelope]) {
        console.warn(`Notification key already '${record.status}', skipping`, key, statusOrEnvelope);
        return record;
      } else if (statusOrEnvelope === record.status) {
        console.debug(`Updating notification key to same status '${record.status}'`, key, statusOrEnvelope);
      }

      record.status = statusOrEnvelope;
    } else {
      // TODO (IW): Need to handle passing in whole envelopes and auto-parsing expected changes
      console.warn("NOT YET IMPLEMENTED");
    }

    TransformDomain.notificationKeys.set(key, record);
    TransformDomain.notificationKeysSubject.next(TransformDomain.notificationKeys);

    return record;
  }

  protected static deleteNotificationKey = (key: string) => {
    const success = TransformDomain.notificationKeys.delete(key);
    TransformDomain.notificationKeysSubject.next(TransformDomain.notificationKeys);

    return success;
  };

  static get notificationKeys$() {
    return TransformDomain.notificationKeysSubject.source;
  }

  static syncStatus$ = pipe(
    concat([fromValue(TransformDomain.notificationKeys), TransformDomain.notificationKeys$]),
    debounce(() => 50),
    map((keys) => {
      // Grab status of all notification keys
      const statuses = Array.from(keys.entries()).map(([, v]) => v.status);
      return getSyncStatus(statuses);
    })
  );

  static syncStatusKeys$ = pipe(
    concat([fromValue(TransformDomain.notificationKeys), TransformDomain.notificationKeys$]),
    debounce(() => 50),
    map((keys) => keys)
  );

  static assistStatus$ = pipe(
    concat([fromValue(TransformDomain.notificationKeys), TransformDomain.notificationKeys$]),
    debounce(() => 50),
    map((keys) => {
      // Grab status of all notification keys expecting assist runs
      const statuses = Array.from(keys.entries())
        .filter(([, v]) => !!v.assist)
        .map(([, v]) => v.status);
      return getSyncStatus(statuses);
    })
  );

  constructor(...args) {
    // @ts-ignore
    super(...args);

    // *** Global watchers ***
    if (!TransformDomain.watching && !!this.ws) {
      TransformDomain.watching = true;

      // Track all received notification keys
      pipe(
        this.ws.source$,

        // FIXME (IW): Find a better place for this
        //
        // Subscribe to all assist events so we can properly show the status indicator
        onStart(() => {
          this.ws.subscribe(AssistPlannedSubscription);
          this.ws.subscribe(AssistCompletedSubscription);
        }),
        onEnd(() => {
          this.ws.unsubscribe(AssistPlannedSubscription);
          this.ws.unsubscribe(AssistCompletedSubscription);
        }),

        forEach((envelope) => {
          const type = envelope.type;

          if (!!envelope.data?.notificationKeys) {
            console.info("Found envelope with notification keys in the data", type, envelope);
          }

          // Assist notifications
          if ([SubscriptionType.AssistPlanned, SubscriptionType.AssistCompleted].includes(type)) {
            const status =
              SubscriptionType.AssistPlanned === type ? NotificationKeyStatus.Planned : NotificationKeyStatus.Completed;

            envelope.notificationKeys?.forEach((key: string) => TransformDomain.updateNotificationKey(key, status));
            envelope.data?.notificationKeys?.forEach((key: string) =>
              TransformDomain.updateNotificationKey(key, status)
            );
            return;
          }

          // Other notification types
          envelope.notificationKeys?.forEach((key: string) => {
            const notificationKey = TransformDomain.notificationKeys.get(key);

            if (!notificationKey) {
              console.warn("Received unexpected notification key", key);
            }

            TransformDomain.updateNotificationKey(
              key,
              !notificationKey?.assist ? NotificationKeyStatus.Completed : NotificationKeyStatus.Received
            );
          });
        })
      );

      // clear out terminated notification keys
      pipe(
        interval(1000 * 60), // 1m
        forEach(() => {
          const now = new Date();
          console.debug("checking for expired notification keys");

          TransformDomain.notificationKeys.forEach((record, key) => {
            if (NotificationStatusIndex[record.status] >= NotificationStatusIndex[NotificationKeyStatus.Timedout]) {
              console.debug("clearing terminated notification key", key, record.status, record);
              TransformDomain.deleteNotificationKey(key);
            } else if (record.expires.getTime() < now.getTime()) {
              console.warn("clearing expired notification key", key, record.expires, record);
              TransformDomain.updateNotificationKey(key, NotificationKeyStatus.Timedout);
            }
          });
        })
      );
    }

    // *** Instance watchers ***

    // Clear out terminated expected changes
    pipe(
      this.notificationKeys$,
      forEach((keys) => {
        Array.from(keys.entries()).forEach(([key, record]) => {
          switch (record.status) {
            case NotificationKeyStatus.Completed:
            case NotificationKeyStatus.Failed:
            case NotificationKeyStatus.Timedout:
              this.clearExpectedChange(key, record.status);
              break;
          }
        });
      })
    );

    // Clear out expired expected changes
    pipe(
      interval(1000 * 30), // 30s
      forEach(() => {
        this.expireExpectedChanges();
      })
    );
  }

  public deserialize?: (dto: DT) => RT;
  public serialize?: (resource: Partial<RT>) => Partial<DT>;

  protected generateUid(action?: string, pk?: string | number): string;
  protected generateUid(action?: string, item?: Partial<RT>): string;
  protected generateUid(action?: string, itemOrPk?: Partial<RT> | string | number): string;
  protected generateUid(action?: string, itemOrPk?: Partial<RT> | string | number): string {
    const pk = ["string", "number"].includes(typeof itemOrPk) ? itemOrPk : this.getPk(itemOrPk as RT);

    const uid = `${this.resource}_${undefined !== pk ? pk + "_" : ""}${
      !!action ? action.replace(/[\s_]/g, "-") + "_" : ""
    }${nanoid().replace(/_/g, "-")}`;

    return uid;
  }

  protected parseUid(str: string) {
    return parseNotificationUuid(str);
  }

  private upsertSubject = makeSubject<RT[]>();

  protected get upsert$() {
    return this.upsertSubject.source;
  }

  upsert = (data: RT | RT[] | null) => {
    if (!!data && (!Array.isArray(data) || !!data.length)) {
      const items = Array.isArray(data) ? data : [data];
      this.upsertSubject.next(items);
    }
    return data;
  };

  // protected upsert = (data: DtoType | DtoType[] | null) => {
  //   if (!!data && !!this.deserialize) {
  //     const items: DtoType[] = Array.isArray(data) ? data : [data];
  //     const list = items.map((i: DtoType) => this.deserialize?.(i)).filter(notUndefined);

  //     if (!!list.length) this.upsertSubject.next(list);
  //   }
  //   return data;
  // }

  protected get notificationKeys(): Map<string, NotificationKeyRecord> {
    return new Map(
      Array.from(TransformDomain.notificationKeys.entries()).filter(
        ([k]) => this.parseUid(k).resource === this.resource
      )
    );
  }

  protected get notificationKeys$() {
    return pipe(
      TransformDomain.notificationKeys$,
      map((keys) => new Map(Array.from(keys.entries()).filter(([k]) => this.parseUid(k).resource === this.resource)))
    );
  }

  protected addNotificationKey(key: string, status?: NotificationKeyStatus, assist?: boolean): NotificationKeyRecord;
  protected addNotificationKey(
    key: string,
    expectedChanges: ExpectedChangesMap,
    status?: NotificationKeyStatus,
    assist?: boolean
  ): NotificationKeyRecord;
  protected addNotificationKey(
    key: string,
    expectedChangesOrStatus?: ExpectedChangesMap | NotificationKeyStatus,
    statusOrAssist?: NotificationKeyStatus | boolean,
    assist?: boolean
  ) {
    if (typeof expectedChangesOrStatus === "string") {
      return TransformDomain.addNotificationKey(key, undefined, expectedChangesOrStatus, statusOrAssist as boolean);
    } else {
      return TransformDomain.addNotificationKey(
        key,
        expectedChangesOrStatus,
        statusOrAssist as NotificationKeyStatus,
        assist
      );
    }
  }

  protected updateNotificationKey(key: string, envelope: Envelope): NotificationKeyRecord;
  protected updateNotificationKey(key: string, status: NotificationKeyStatus): NotificationKeyRecord;
  protected updateNotificationKey(
    key: string,
    statusOrEnvelope: NotificationKeyStatus | Envelope
  ): NotificationKeyRecord {
    return TransformDomain.updateNotificationKey(key, statusOrEnvelope);
  }

  protected manageNotificationKey<F extends (...args: unknown[]) => Promise<unknown>>(
    func: F,
    action: string,
    options: ManageNotificationKeyOptions<F, RT> = {}
  ): F {
    return (async (...args: Parameters<F>) => {
      const { getPatch, getPk, optimistic, errorizer = () => `Failed to run action: ${action}` } = options;

      const pk = getPk?.(...args);
      const notificationKey = this.generateUid(action, pk);

      if (pk) this.expectChange(notificationKey, pk, getPatch?.(...args) || {}, optimistic);
      else this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending);

      let res: unknown;

      try {
        res = await func(...args);
        if (pk) this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        else this.updateNotificationKey(notificationKey, NotificationKeyStatus.Completed);
      } catch (cause) {
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);

        switch (typeof errorizer) {
          case "string":
            throw new Error(errorizer, { cause });
          default:
            const error = errorizer(cause, ...args);
            switch (typeof error) {
              case "string":
                throw new Error(error, { cause });
              default:
                throw error;
            }
        }
      }

      return res;
    }) as F;
  }

  protected manageErrorsAndNotificationKey<
    F extends (...args: unknown[]) => TypedErrorPromise<unknown, NetworkError | ReclaimApiError>
  >(
    method: F,
    action: string,

    options: ManageNotificationKeyOptions<F, RT> & {
      handleHttpErrors?: {
        [status in HttpErrorStatusCode]?: boolean | ((error: ReclaimApiError) => boolean | void);
      };
    } = {}
  ): F {
    const { handleHttpErrors, ...manageNotificationKeyOptions } = options;
    return this.typedManageErrors(
      this.manageNotificationKey(method, action, manageNotificationKeyOptions),
      handleHttpErrors
    );
  }

  protected deserializeResponse<
    F extends (
      ...args: unknown[]
    ) => TypedErrorPromise<DT | null | void | NetworkError | ReclaimApiError, NetworkError | ReclaimApiError>
  >(method: F): (...args: Parameters<F>) => Promise<RT | null>;
  protected deserializeResponse<
    F extends (
      ...args: unknown[]
    ) => TypedErrorPromise<DT[] | null | NetworkError | ReclaimApiError, NetworkError | ReclaimApiError>
  >(method: F): (...args: Parameters<F>) => Promise<RT[] | null>;
  protected deserializeResponse<
    F extends (
      ...args: unknown[]
    ) => TypedErrorPromise<DT | DT[] | null | void | NetworkError | ReclaimApiError, NetworkError | ReclaimApiError>
  >(method: F): (...args: Parameters<F>) => Promise<RT | RT[] | null> {
    return async (...args: Parameters<F>) => {
      const returned = await method(...args);
      if (!returned) return null;
      return transformMap<DT, RT>(returned as DT, this.deserialize);
    };
  }

  /*** Transition from react-query and old cache to observables ***/

  protected expectedChanges = new Map<string | number, ExpectedChange<RT>>();
  protected expectedChangesSubject = makeSubject<Map<string | number, ExpectedChange<RT>>>();

  get expectedChanges$() {
    return pipe(
      this.expectedChangesSubject.source,
      debounce(() => 50)
    );
  }

  protected expectChange(
    notificationKey: string,
    pk: string | number,
    patch: Partial<RT>,
    assist: boolean = false,
    optimistic: boolean = false,
    resource?: RT
  ) {
    if (this.expectedChanges.has(pk)) {
      console.debug("Already expecting change, merging", notificationKey, pk, this.expectedChanges.get(pk));
    }

    const mergedPatch = !!this.expectedChanges.has(pk)
      ? deepmerge(this.expectedChanges.get(pk)?.patch || {}, patch)
      : patch;

    const record: ExpectedChange<RT> = {
      notificationKey,
      pk,
      expires: add(new Date(), { seconds: 30 }),
      patch: mergedPatch,
      assist,
      optimistic,
      resource,
    };

    console.debug("expect change", notificationKey, pk, patch, record);

    this.expectedChanges.set(pk, record);

    this.expectedChangesSubject.next(this.expectedChanges);
    this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, assist);
  }

  protected patchExpectedChanges<D extends RT | RT[] | null>(data: D): D {
    if (!data) return data;

    let updated = false;

    const patch = (item: RT) => {
      const pk = this.getPk(item);
      const expectedChange = !!pk && this.expectedChanges.get(pk);

      // Clear expected changes not waiting on assist
      if (!!expectedChange && partialEquals(item, expectedChange.patch) && !expectedChange.assist) {
        this.expectedChanges.delete(pk);
        updated = true;
      }

      return !!expectedChange && !!expectedChange.patch ? deepmerge({}, item, expectedChange.patch) : item;
    };

    // Update expected changes subject
    if (updated) this.expectedChangesSubject.next(this.expectedChanges);

    const patched = Array.isArray(data) ? data.map(patch) : patch(data as RT);
    return patched as D;
  }

  protected expireExpectedChanges() {
    if (!this.expectedChanges.size) return;

    const now = new Date();
    let updated = false;

    this.expectedChanges.forEach((change) => {
      if (change.expires.getTime() > now.getTime()) return;

      console.debug("expire expected change", change);

      this.expectedChanges.delete(change.pk);
      this.onExpectedChangeError("timeout", change);

      updated = true;
    });

    // Update expected changes subject
    if (updated) this.expectedChangesSubject.next(this.expectedChanges);
  }

  protected clearExpectedChange(
    notificationKey: string | number,
    status: NotificationKeyStatus = NotificationKeyStatus.Completed
  ) {
    let updated = false;

    console.debug("clear expected change", notificationKey, status);

    Array.from(this.expectedChanges.entries())
      .filter(([, change]) => change.notificationKey === notificationKey)
      .forEach(([key, change]) => {
        this.expectedChanges.delete(key);

        if (NotificationKeyStatus.Timedout === status) {
          this.onExpectedChangeError("timeout", change);
        }

        updated = true;
      });

    // Update expected changes subject
    if (updated) this.expectedChangesSubject.next(this.expectedChanges);
  }

  protected onExpectedChangeError = (
    errorType: "mismatch" | "timeout",
    change: ExpectedChange<RT>,
    other?: unknown
  ) => {
    if (!!change.notificationKey && errorType === "timeout") {
      return console.error(
        `Expected changes from ${this.resource} api request but timed out waiting for notification to return via ws.`
      );
    }
    if (!!change.notificationKey && errorType === "mismatch") {
      try {
        return console.error(
          `Expected changes to ${this.resource}(${change.pk}) matching '${JSON.stringify(
            change.patch
          )}', but received '${JSON.stringify(other || "")}' instead`
        );
      } catch (e) {
        console.error("Failed to parse payload from server", e);
      }
    }

    if (!change.notificationKey && errorType === "timeout") {
      return console.error(
        `Expected changes to ${this.resource} but the server never responded with the expected payload`
      );
    }
  };
}

/**
 * 2022-10-25
 * Type will enforce minimum number of digits, but cannot enforce maximum
 */
export type YYYYMMDD = `${number}${number}${number}${number}-${number}${number}-${number}${number}`;

/**
 * 13:30
 * Type will enforce minimum number of digits, but cannot enforce maximum
 */
export type HHMM = `${number}:${number}${number}`;
/**
 * 13:30:22
 * Type will enforce minimum number of digits, but cannot enforce maximum
 */
export type HHMMSS = `${number}:${number}${number}:${number}${number}`;
/**
 * 13:30:22 or 13:30
 * Type will enforce minimum number of digits, but cannot enforce maximum
 */
export type TimeString = HHMMSS | HHMM;

/**
 * return type for functions which return states for `loading`, `error`
 * and a data type.  Typed so that `loading`, `error` and the data are
 * mutally exclusively defined.
 */
export type LoadingErrorData<T, DATA_KEY extends string = "data"> =
  | ({
      [key in DATA_KEY]: T;
    } & {
      error: undefined;
      loading: false;
      state: "hasData";
    })
  | ({
      [key in DATA_KEY]: undefined;
    } &
      (
        | {
            error: Error;
            loading: false;
            state: "hasError";
          }
        | {
            error: undefined;
            loading: true;
            state: "loading";
          }
      ));

/**
 * return type for functions which return states for `loading` and a
 * data type.  Typed so that `loading` and the data are mutally
 * exclusively defined.
 */
export type LoadingData<T, DATA_KEY extends string = "data"> =
  | ({
      [key in DATA_KEY]: T;
    } & {
      loading: false;
    })
  | ({
      [key in DATA_KEY]: undefined;
    } & {
      loading: true;
    });
