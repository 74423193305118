import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";
import { ClassNameMap } from "@mui/styles";
import clsx from "clsx";
import { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useState } from "react";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    tooltip: {
      maxWidth: 280,
    },
    tooltipDisabled: {
      display: "none",
    },
    titleWrapper: {
      "& button, & a": {
        color: theme.palette.primary.light,
      },
    },
  }),
  { classNamePrefix: "Tooltip" }
);

export type TooltipJSSClassKey = keyof ReturnType<typeof useStyles>;
export type TooltipTitleFnHelpers = { closeTooltip: () => void };
export type TooltipTitleFn = (helpers: TooltipTitleFnHelpers) => NonNullable<ReactNode>;
export type TooltipTitle = NonNullable<ReactNode> | TooltipTitleFn;

export type TooltipProps = Omit<MuiTooltipProps, "classes" | "title" | "disableInteractive"> & {
  className?: string;
  classes?: Partial<ClassNameMap<TooltipJSSClassKey>>;
  TooltipClasses?: MuiTooltipProps["classes"];
  disabled?: boolean;
  disableTooltip?: boolean;
  letEventsPropagate?: boolean;
  title: TooltipTitle;
  closeOnClick?: boolean;
  withSpan?: boolean;
  interactive?: boolean
};

export const Tooltip: FC<TooltipProps> = ({
  className,
  classes: extClasses,
  children,
  disabled,
  TooltipClasses,
  title,
  letEventsPropagate,
  closeOnClick,
  withSpan,
  interactive,
  ...rest
}) => {
  const classes = useStyles({ classes: extClasses });

  const [open, setOpen] = useState(false);

  const openTooltip = useCallbackSafeRef(() => setOpen(true));
  const closeTooltip = useCallbackSafeRef(() => setOpen(false));

  const stopProp = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => !letEventsPropagate && e.stopPropagation(),
    [letEventsPropagate]
  );

  useEffect(() => {
    window.addEventListener("blur", closeTooltip);
    return () => window.removeEventListener("blur", closeTooltip);
  }, [closeTooltip]);

  return (
    <MuiTooltip
      className={clsx(className, classes.root)}
      disableInteractive={!interactive}
      open={open}
      onOpen={openTooltip}
      onClose={closeTooltip}
      PopperProps={{
        onClick: (e) => {
          e.stopPropagation();
          if (closeOnClick) closeTooltip();
        },
      }}
      title={
        <Box className={classes.titleWrapper} onMouseDown={stopProp} onMouseUp={stopProp}>
          {typeof title === "function" ? title({ closeTooltip }) : title}
        </Box>
      }
      classes={{
        ...TooltipClasses,
        tooltip: clsx(classes.tooltip, TooltipClasses?.tooltip, {
          [classes.tooltipDisabled]: disabled,
        }),
      }}
      arrow
      {...rest}
    >
      {withSpan ? <span>{children}</span> : children}
    </MuiTooltip>
  );
};
